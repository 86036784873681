import { useState, useEffect } from 'react'
import { Transaction, User } from './types'
import { getPeriodTransactions } from '../../services/transactions'
import { getUsers } from '../../services/users'

export const useDashboardData = (
  userToken: string
): {
  activePeriod: string
  setActivePeriod: React.Dispatch<React.SetStateAction<string>>
  transactions: Transaction[]
  users: User[]
  loading: boolean
  error: string | null
} => {
  const [activePeriod, setActivePeriod] = useState('2025-03-30')
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setLoading(true)
        const [transactionsData, usersData] = await Promise.all([
          getPeriodTransactions(userToken, activePeriod),
          getUsers(userToken)
        ])
        setTransactions(transactionsData.data)
        setUsers(usersData.data)
        setLoading(false)
      } catch (err) {
        setError('Failed to fetch dashboard data')
        setLoading(false)
      }
    }
    fetchData()
  }, [userToken, activePeriod])

  return { activePeriod, setActivePeriod, transactions, users, loading, error }
}

import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import dateFormat from '../helpers/dateFormat'

interface PeriodSelectorProps {
  activePeriod: string
  setActivePeriod: (period: string) => void
}

const activePeriods = [
  '2023-08-30',
  '2023-09-30',
  '2023-10-30',
  '2023-11-30',
  '2023-12-30',
  '2024-01-30',
  '2024-02-28',
  '2024-03-30',
  '2024-04-30',
  '2024-05-30',
  '2024-06-30',
  '2024-07-30',
  '2024-08-30',
  '2024-09-30',
  '2024-10-30',
  '2024-11-30',
  '2024-12-30',
  '2025-01-30',
  '2025-02-28',
  '2025-03-30',
  '2025-04-30',
  '2025-05-30',
  '2025-06-30',
  '2025-07-30'
]

const PeriodSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
`

const ArrowButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 1rem;
`

const PeriodDisplay = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 1rem;
`

const PeriodSelector: React.FC<PeriodSelectorProps> = ({
  activePeriod,
  setActivePeriod
}) => {
  const currentDate = moment()
  const lastAvailableMonth = currentDate.endOf('month').format('YYYY-MM-DD')
  const validPeriods = activePeriods.filter(
    (period) => period <= lastAvailableMonth
  )
  const currentIndex = validPeriods.indexOf(activePeriod)

  const handlePrevious = (): void => {
    if (currentIndex > 0) {
      setActivePeriod(validPeriods[currentIndex - 1])
    }
  }

  const handleNext = (): void => {
    if (currentIndex < validPeriods.length - 1) {
      setActivePeriod(validPeriods[currentIndex + 1])
    }
  }

  return (
    <PeriodSelectorContainer>
      <ArrowButton onClick={handlePrevious} disabled={currentIndex === 0}>
        &#8592;
      </ArrowButton>
      <PeriodDisplay>
        {dateFormat(activePeriod, 'month')}{' '}
        {moment(activePeriod).format('YYYY')}
      </PeriodDisplay>
      <ArrowButton
        onClick={handleNext}
        disabled={currentIndex === activePeriods.length - 1}
      >
        &#8594;
      </ArrowButton>
    </PeriodSelectorContainer>
  )
}

export default PeriodSelector
